//@ts-check
import React, { useContext, useState } from "react";
import { Link, navigate } from "gatsby";

import { Button, Checkbox, Form } from "antd";
import { Auth } from "aws-amplify";
import { AuthContext } from "../../context/AuthContext";
import { isBrowser } from "../../utils/SSR";

const ForgotPasswordConfirm = () => {
  const authContext = useContext(AuthContext);
  const [error, setError] = useState("");

  const confirmSubmit = async ({ email, code, new_password, remember }) => {
    try {
      await Auth.forgotPasswordSubmit(email.toLowerCase(), code, new_password);

      if (remember) {
        isBrowser() &&
          window.localStorage.setItem("savedEmailLogin", email.toLowerCase());
      } else {
        isBrowser() && window.localStorage.setItem("savedEmailLogin", "");
      }

      navigate("/profile-authentication");
    } catch (e) {
      setError("Unable to log in with that email and password");
    }
  };

  const loginSubmitFailed = () => {
    console.log("form submit failed");
  };

  const email =
    (isBrowser() && window.localStorage.getItem("passwordResetEmail")) || "";

  return (
    <div className="login-form">
      <div style={{ color: "black", paddingBottom: "10px" }}>
        We've sent you an email with a login code. Enter the code to confirm
        your email address.
      </div>

      <Form
        name="basic"
        wrapperCol={{ span: 24 }}
        onFinish={confirmSubmit}
        onFinishFailed={loginSubmitFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Email"
          className="form-group"
          name="email"
          initialValue={email || ""}
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            { required: true, message: "Please input your email!" },
          ]}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Email Address"
            onChange={() => setError("")}
          />
        </Form.Item>

        <Form.Item
          //label="Email"
          className="form-group"
          label="Code"
          name="code"
          rules={[
            {
              required: true,
              message: "Please input the code sent to your email address",
            },
          ]}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Code"
            onChange={() => setError("")}
          />
        </Form.Item>

        <Form.Item
          //label="Email"
          className="form-group"
          label="New Password"
          name="new_password"
          rules={[
            { required: true, message: "Please input your new password!" },
          ]}
        >
          <input
            type="password"
            className="form-control"
            placeholder="New Password"
            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
            onChange={() => setError("")}
          />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked">
          <div className="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
            <Checkbox>Remember me</Checkbox>
          </div>
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" style={{ height: "50px" }}>
            Log In
          </Button>
        </Form.Item>
        {error && <div>{error}</div>}
      </Form>
    </div>
  );
};

export default ForgotPasswordConfirm;
